import { DefaultColumnFilter } from '@jumbo/components/TableComponents/table-utils'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { matchSorter } from 'match-sorter'
import React, { forwardRef, useEffect } from 'react'
import { useAsyncDebounce, useFilters, useGlobalFilter, useTable, useColumnOrder, usePagination, useSortBy } from 'react-table'
import { AppTheme } from 'theme/customeTheme'
import TablePagination from './TablePagination'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import dragIcon from '../../../assets/drag.svg';
import { useSticky } from 'react-table-sticky'
import DraggableRow from './DraggableRow'
import update from 'immutability-helper'
import { UpdateTableData } from 'redux/actions/Table.action'
import { useDispatch } from 'react-redux'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "1rem",
        '& .MuiPaginationItem-icon': {
            color: '#3F7786',
        },
        '& .MuiTableCell-root': {
            textAlign: "center",
            padding: "12px",
            fontFamily: '"Mulish", sans-serif',
            fontWeight: 600,
            minWidth: "130px"
        },
        '& .MuiPaginationItem-outlined[aria-label="Go to next page"], & .MuiPaginationItem-outlined[aria-label="Go to previous page"], & .MuiPaginationItem-outlined[aria-label="Go to first page"], & .MuiPaginationItem-outlined[aria-label="Go to last page"]': {
            backgroundColor: '#DBE7EA',
            border: 0
        },
        '& .MuiPaginationItem-page.Mui-selected': {
            borderColor: '#3F7786',
        },
        '& .MuiPaginationItem-ellipsis': {
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "4px",
            height: "32px"
        },
        '& .MuiSelect-select:focus': {
            borderRadius: 4,
        },
        '& .MuiTableCell-head': {
            color: AppTheme.colors.black,
            fontWeight: 600
        },
    },
    Pagination: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}));
const DragIcon = (column) => {
    return (
        column === "selection" || column === "id" || column === "action" ? "" :
            <img src={dragIcon} alt="drag" className="ml-2 cursor-grab" />
    )
}
function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val
const ReactMainTable = forwardRef(({ columns, data, applyFilters, itemsPerPage, currentPage, TotalLength, getPageData, orderByColumns }, ref) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )
    const initialState = {
        selectedFlatRows: [],
        pageIndex: currentPage,
        pageSize: itemsPerPage,
    };
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        allColumns,
        headers,
        setColumnOrder,
        gotoPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, filters },
    } = useTable({
        columns,
        data,
        initialState,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        manualFilters: true,
        manualPagination: true,
        pageCount: Math.ceil(TotalLength / itemsPerPage),
    },
        useColumnOrder,
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy, useSticky,
        usePagination,
    )
    const handleChange = (e, p) => {
        gotoPage(p)
    };
    const onNumberPagesChanged = (val) => {
        setPageSize(Number(val))
        gotoPage(1)
    }
    const onApplyFilter = useAsyncDebounce(() => {
        applyFilters(filters)
    }, 200)
    useEffect(() => {
        onApplyFilter();
    }, [filters]);
    useEffect(() => {
        getPageData(pageIndex, pageSize)
        console.log("pageSize, pageIndex", pageIndex, pageSize);
    }, [pageSize, pageIndex]);

    const moveRow = (dragIndex, hoverIndex) => {
        const dragRecord = data[dragIndex]
        dispatch(UpdateTableData(update(data, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRecord],
            ],
        })))

    }
    return (
        <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="table-container">
                <DndProvider backend={HTML5Backend}>
                    <TableContainer className={clsx(classes.root, "custom-scrollbar", "table-responsive")}>
                        <Table {...getTableProps()}
                            // stickyHeader
                            aria-label="sticky table"
                            style={{ border: 'none', borderSpacing: '0', width: '100%', position: 'relative', }}
                        >
                            <TableHead>
                                <DragDropContext
                                    // onDragUpdate={(result) => {
                                    //     console.log("debug result", result);
                                    //     if (result?.source?.index && result?.destination?.index)
                                    //         setColumnOrder(array_move(allColumns?.map(o => o.id), result?.source?.index, result?.destination?.index))
                                    // }}
                                    onDragUpdate={(result) => {
                                        setColumnOrder(array_move(visibleColumns?.map(o => o.id), result?.source?.index, result?.destination?.index))
                                    }}
                                >
                                    <Droppable droppableId={"droppable"} direction="horizontal" >
                                        {(droppableProvided, snapshot) => {
                                            return (
                                                <TableRow
                                                    style={{
                                                        backgroundColor: '#FFF6F6',
                                                    }}
                                                    ref={droppableProvided.innerRef}
                                                    {...droppableProvided.droppableProps}
                                                >
                                                    <TableCell
                                                        style={{
                                                            backgroundColor: '#FFF6F6',
                                                            cursor: 'grab',
                                                            outline: 'none',
                                                            position: 'sticky',
                                                        }}>
                                                        move
                                                        </TableCell>
                                                    {visibleColumns.map((column, headerIndex) => (
                                                        <Draggable
                                                            draggableId={column.id}
                                                            key={column.id}
                                                            index={headerIndex}
                                                        >
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <TableCell
                                                                        onClick={() => orderByColumns(column.id)}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={provided.innerRef}
                                                                        style={{
                                                                            backgroundColor: '#FFF6F6',
                                                                            cursor: 'grab',
                                                                            outline: 'none',
                                                                            position: column?.sticky === 'left' ? 'sticky' : 'initial',
                                                                        }} {...column.getHeaderProps()}>
                                                                        {column.render('Header')}
                                                                        {DragIcon(column.id)}
                                                                    </TableCell>
                                                                )
                                                            }}
                                                        </Draggable>
                                                    ))}
                                                    {droppableProvided.placeholder}
                                                </TableRow>
                                            )
                                        }}
                                    </Droppable>
                                </DragDropContext>
                                <TableRow className="tr bg-white">
                                    <TableCell> </TableCell>
                                    {visibleColumns.map((column, index) => (
                                        <TableCell {...column.getHeaderProps()} className={"th align-middle border-top-0 border-bottom-0 bg-white fixed-column-" + index}>
                                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                                        </TableCell>
                                    ))}
                                </TableRow>

                            </TableHead>
                            <TableBody {...getTableBodyProps()}>
                                {rows.map((row, i) => {
                                    return prepareRow(row) || (
                                        <DraggableRow
                                            index={i}
                                            row={row}
                                            moveRow={moveRow}
                                            {...row.getRowProps()}
                                        />
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DndProvider>
            </div>
            <TablePagination classes={classes} pageIndex={pageIndex - 1} pageSize={pageSize} TotalLength={TotalLength} handleChange={handleChange} onNumberPagesChanged={onNumberPagesChanged} />
        </React.Fragment>
    )
})
export default ReactMainTable