import React from 'react';

const CustomInfo = ({ name }) => {

    return (
        <>
            {name == "0" ?
                <img title='block' src="/images/Info-Flag1.png" alt="block" className="info-flag" />
                : 
                <img title='InProgress' src="/images/Info-Flag2.png" alt="InProgress" className="info-flag" />}
        </>
    )
}

export default CustomInfo;