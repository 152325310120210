import { setTableData, setManagersData, setFoldersData, setAllprojectLabel, setAllworkCardLabel, setTrelloUsersData, setUpdatedCardDetails, setUpdatedChatConversation } from "redux/actions/Table.action";
import { fetchError, fetchStart, fetchSuccess } from "../../redux/actions";
import axios from '../config';
const TableServices = {
    getTableData: ({ limit, currentPage, orderByColumn, columnOrderType, ...filtersToApply }) => {

        const queryString = new URLSearchParams(filtersToApply).toString();
        // console.log("queryString >>> ", queryString);
        // console.log("filtersToApply >>> ", filtersToApply);
        return dispatch => {
            try {
                dispatch(fetchStart());
                dispatch(setTableData({}));
                axios
                    .get('trello_cards/all?' + `limit=${limit}&page=${currentPage}&orderBy=${orderByColumn}&orderType=${columnOrderType}${queryString ? ("&" + queryString) : ""}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setTableData(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                        if (error?.response?.data?.status == "403") {
                            // console.log("error 403 occured");
                            localStorage.removeItem("userInfo");
                            alert(error?.response?.data?.message);
                            window.location = './signin';
                        }
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    getFoldersData: () => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get('trello_cards/get-folder-list?')
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setFoldersData(res?.data?.data?.map((val) => {
                                return {
                                    value: val.Folder,
                                    label: val.Folder,
                                }
                            }
                            )));
                        } else {
                            dispatch(fetchError(res.data.error));
                            console.log("error 1:", res);
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                        if (error?.response?.data?.status == "403") {
                            // console.log("error 403 occured");
                            localStorage.removeItem("userInfo");
                            alert(error?.response?.data?.message);
                            window.location = './signin';
                        }
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
                console.log("error 3:", error);
            }
        };
    },
    getAllProjectLabel: () => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get('trello_cards/highlights')
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // console.log('getAllProjectLabel: res data', res?.data?.data);
                            dispatch(setAllprojectLabel(res?.data?.data.map((val, i) => {
                                return {
                                    value: val.label,
                                    label: val.label,
                                }
                            }
                            )));
                        } else {
                            dispatch(fetchError(res.data.error));
                            console.log("error 1:", res);
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                        if (error?.response?.data?.status == "403") {
                            // console.log("error 403 occured");
                            localStorage.removeItem("userInfo");
                            alert(error?.response?.data?.message);
                            window.location = './signin';
                        }
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
                console.log("error 3:", error);
            }
        };
    },
    getAllWorkCardLabel: () => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get('work_cards/work_highlights')
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // console.log('setAllworkCardLabel: res data', res?.data?.data);
                            dispatch(setAllworkCardLabel(res?.data?.data.map((val, i) => {
                                return {
                                    value: val.label,
                                    label: val.label,
                                }
                            }
                            )));
                        } else {
                            dispatch(fetchError(res.data.error));
                            console.log("error 1:", res);
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                        if (error?.response?.data?.status == "403") {
                            // console.log("error 403 occured");
                            localStorage.removeItem("userInfo");
                            alert(error?.response?.data?.message);
                            window.location = './signin';
                        }
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
                console.log("error 3:", error);
            }
        };
    },
    getManagersData: () => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get('trello_member/get-members?')
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // console.log('managerData: res data', res?.data);
                            dispatch(setManagersData(res?.data?.data?.map((val) => {
                                return {
                                    value: val.id,
                                    label: val.fullName,
                                }
                            }
                            )));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    getTrelloUsers: () => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get('user/all')
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // console.log('TrelloUsersData: res data', res?.data);
                            dispatch(setTrelloUsersData(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    updateTrelloCardDetails: (updateCardDetails) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .put('trello_cards/update-card-details', updateCardDetails)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setUpdatedCardDetails(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    updateConversations: (chatConversation) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post('thread_message/createMessage', chatConversation)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // dispatch(setUpdatedChatConversation(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
};

export default TableServices;
