import { setDashboardData, setNotificationData } from "redux/actions/Dashboard.action";
import { fetchError, fetchStart, fetchSuccess } from "../../redux/actions";
import axios from '../config';
const DashboardServices = {
    getDashboardData: () => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get('dashboard/data?')
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setDashboardData(res?.data?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                            console.log("error 1:", res);
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                        if (error.response.data.status == "403") {
                            // console.log("error 403 occured");
                            localStorage.removeItem("userInfo");
                            alert(error.response.data.message);
                            window.location = './signin';
                        }
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
                console.log("error 3:", error);
            }
        };
    },
    getNotificationData: () => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get('notification/all')
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setNotificationData(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                            console.log("error 1:", res);
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                        if (error.response.data.status == "403") {
                            // console.log("error 403 occured");
                            localStorage.removeItem("userInfo");
                            alert(error.response.data.message);
                            window.location = './signin';
                        }
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
                console.log("error 3:", error);
            }
        };
    },
}
export default DashboardServices;