import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MessageIcon from "@material-ui/icons/Message";
import ShareIcon from "@material-ui/icons/Share";
import EmailIcon from "@material-ui/icons/Email";
import CakeIcon from "@material-ui/icons/Cake";
import CmtMediaObject from "../../../../../../@coremat/CmtMediaObject";
import CmtAvatar from "../../../../../../@coremat/CmtAvatar";
import { getDateElements } from "../../../../../utils/dateHelper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { fade } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  feedItemRoot: {
    padding: "10px 0",
    position: "relative",
    fontFamily: "poppins",
    borderBottom: `1px solid ${fade(theme.palette.common.dark, 0.035)}`,
    "& .Cmt-media-object": {
      alignItems: "center"
    },
    "& .Cmt-media-image": {
      alignSelf: "flex-start",
      width: 56
    },
    "& .Cmt-media-body": {
      width: "calc(100% - 56px)",
      flex: "inherit"
    }
  },
  titleRoot: {
    letterSpacing: 0.25,
    marginBottom: 6,
    cursor: "pointer"
  },
  notificationCard: {
    color: theme.palette.common.white,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px"
  },
  notificationContent: {
    fontSize: "16px",
    color: '#000000',
    marginLeft: 16,
    textDecoration: "underline",
  },
  notificationTime: {
    fontSize: "16px",
    color: '#B3B3B3'
  },
  // cardContent: {
  //   paddingLeft: '15px'
  // },
}));

const getInvitationContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="blue">
      {item.metaData.user.name}
    </Box>
    <Box component="span" color="blue" ml={1}>
      {item.metaData.group.name}
    </Box>
  </Typography>
);

const NotificationItem = ({ item }) => {
  // console.log("notification Item::".item);
  const classes = useStyles();

  return (
    <Box className={classes.feedItemRoot}>
      <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.cardContent}>
        <Box display="flex" alignItems="center">
          {
            item?.notificationType === 'NEW_PROJECT' &&
            <img src="/images/new project.png" className="New project" />
          }
          {
            item?.notificationType === 'TAGGED' &&
            <img src="/images/mention.png" className="mention" />
          }
          {
            item?.notificationType === 'TASK_ASSIGNED' &&
            <img src="/images/assignment.png" className="Assignment" />
          }
         
          {/* <img src="/images/completed.png" className="completed" /> */}
          

          {/* <img className="pointer" src={item?.icons} alt="mention" /> */}
          <span className={classes.notificationContent}>{item?.notificationText}</span>
        </Box>
        <em><span className={classes.notificationTime}>{moment(item?.times).fromNow()}</span></em>
      </Box>
    </Box>
  );
};

export default NotificationItem;
