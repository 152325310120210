import { Grid, TextField } from '@material-ui/core';
import React from 'react';

const MultipleCardComponent = ({ cardItem, index, handleBlur, handleChange, fieldName, array }) => {
    const arrayValueChange = (obj) => {
        const Index = array?.findIndex(e => e?.trello_id == obj?.trello_id)
        if (index !== -1) {
            array[Index] = obj
            // console.log("onchane new obj ", array);
            handleChange(array)

        }

    }
    const onItemValueChange = (value) => {
        console.log("onchane", value);
        const temp = {
            ...cardItem,
            ...{ Estimated_Hours: value }
        }
        arrayValueChange(temp)
    }
    return (
        <>
            <Grid item xs={10} sm={10} md={10}>
                <TextField
                    fullWidth
                    disabled
                    value={cardItem.child_card_title}
                    placeholder="Mechanical"
                    variant="outlined"
                    name="cardItem.child_card_title"
                    multiline
                    className="edit-value"
                    style={{
                        maxWidth: "250px"
                    }}
                />
            </Grid>
            <Grid item xs={2} sm={2} md={2}>
                <TextField
                    // fullWidth
                    // error={Boolean(touched.Spent_Mech_Hours && errors.Spent_Mech_Hours)}
                    // helperText={touched.Spent_Mech_Hours && errors.Spent_Mech_Hours}
                    value={cardItem?.Estimated_Hours}
                    placeholder="hour"
                    variant="outlined"
                    name={`cardItem?..${index}.Estimated_Hours`}
                    type='number'
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                    onChange={(e) => {
                        onItemValueChange(e?.target?.value)

                    }}
                    multiline
                    className="edit-value"
                    style={{
                        maxWidth: "60px",
                        float: "right"
                    }}
                />
            </Grid>
        </>
    )
}

export default MultipleCardComponent;