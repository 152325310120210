import {
    STORE_TABLE_DATA, STORE_MANAGERS_DATA, STORE_FOLDERS_DATA, STORE_ALL_PROJECT_LABEL, STORE_ALL_WORKCARD_LABEL, STORE_TRELLO_USERS_DATA, STORE_UPDATED_CARD_DETAIL, STORE_UPDATED_CHAT_CONVERSATION, UPDATE_TABLE_ROW, UPDATE_TABLE_DATA
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
    tableData: {},
    managerData: [],
    folderData: [],
    allProjectLabels: [],
    allWorkCardLabels: [],
    trelloUsersData: [],
    updatedCardDetails: [],
    lastSentMessage: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case STORE_TABLE_DATA: {
            return {
                ...state,
                tableData: action.payload
            };
        }
        case STORE_FOLDERS_DATA: {
            return {
                ...state,
                folderData: action.payload
            };
        }
        case STORE_ALL_PROJECT_LABEL: {
            return {
                ...state,
                allProjectLabels: action.payload
            };
        }
        case STORE_ALL_WORKCARD_LABEL: {
            return {
                ...state,
                allWorkCardLabels: action.payload
            };
        }
        case STORE_MANAGERS_DATA: {
            return {
                ...state,
                managerData: action.payload
            };
        }
        case STORE_TRELLO_USERS_DATA: {
            return {
                ...state,
                trelloUsersData: action.payload
            };
        }
        case STORE_UPDATED_CARD_DETAIL: {
            return {
                ...state,
                updatedCardDetails: action.payload
            };
        }
        case STORE_UPDATED_CHAT_CONVERSATION: {
            return {
                ...state,
                lastSentMessage: action.payload
            };
        }
        case UPDATE_TABLE_ROW: {
            const findIndex = state?.tableData?.trelloCards?.findIndex((item) => item.trello_id === action.payload?.trello_id)
            if (findIndex !== -1) {
                const cars = state?.tableData?.trelloCards.map((item) => {
                    if (item.trello_id === action.payload?.trello_id) {
                        return action.payload
                    }
                    return item
                })
                return {
                    ...state,
                    tableData: {
                        ...state?.tableData,
                        ...{ trelloCards: cars }
                    }
                };
            }

        }
        case UPDATE_TABLE_DATA: {
            return {
                ...state,
                tableData: {
                    ...state?.tableData,
                    ...{ trelloCards: action.payload }
                }
            };
        }
        default:
            return state;
    }
};
