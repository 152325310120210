import { Button } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Axios from 'axios';
import React, { useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { useDispatch, useSelector } from 'react-redux';
import { sendTextMessage } from 'redux/actions/Chat';
import TableServices from 'services/table';
import { AppTheme } from 'theme/customeTheme';

const useStyles = makeStyles(theme => ({
  chatFooterRoot: {
    border: `1px solid #D7D7D7`,
    backgroundColor: "#ECECEC",
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 16,
    '& .mentionTextbox__suggestions': {
      top: 'auto !important',
      bottom: '100%',
      maxHeight: '300px',
      overflowY: 'scroll'
    }
  },
  textFieldRoot: {
    marginRight: 20,
    '& .MuiInputBase-input': {
      fontFamily: '"Mulish", sans-serif',
      fontWeight: 400,
      width: "100% !important",
      padding: "10.5px 14px !important",
      color: "#737B7D",
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 5,
      backgroundColor: "#ffffff"
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: "transparent !important"
    }
  },
  iconBtnRoot: {
    fontFamily: '"Mulish", sans-serif',
    fontSize: 18,
    fontWeight: 700,
    padding: 5,
    color: '#FFFFFF',
    backgroundColor: '#003D8F',
    borderRadius: 5,
    textTransform: 'capitalize'
  },
}));

const ChatFooter = ({ cardType }) => {
  const [message, setMessage] = useState('');
  const [mentions, setMentions] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();
  // const mentions = useState([]);
  const { trelloUsersData } = useSelector(({ table }) => table);
  React.useEffect(() => {
    dispatch(TableServices.getTrelloUsers());
  }, [])
  const onSendMessage = () => {
    if (message) {
      // console.log("onSendMessage", message);
      dispatch(sendTextMessage(message));
      const chatConversation = {
        message: message,
        thread: cardType._id,
        tagged_users: mentions,
      }
      dispatch(TableServices.updateConversations(chatConversation));
      setMessage('');
    }
  };
  const handleChange = (event, newValue) => {
    // const handleChange = (event, newValue,newPlainTextValue) => {
    // console.log("handleChange newValue::", event.target.value,newValue,newPlainTextValue);
    // if(newValue.includes("↵")){

    // }else{
    //   setMessage(newValue);
    //   const regex = /[^{}]+(?=})/g;
    //   setMentions(message.match(regex));
    // }
    setMessage(newValue);
    const regex = /[^{}]+(?=})/g;
    setMentions(message.match(regex));
  }
  function renderSuggestion(entry, search, highlightedDisplay, index, focused) {
    // Modify the suggestion dropdown by returning valid JSX
    return (
      <>
        <span>{entry.display}</span>
      </>
    );
  }
  const defaultStyle = {
    width: '552px',
    height: '45px',
    backgroundColor: 'white',
    marginRight: '20px',
    borderRadius: '5px',
    fontSize: '16px'
  };
  const defaultMentionStyle = {
    fontWeight: "600",
    backgroundColor: "#cee4e5",
    margin: "5px",
  };
  // console.log('trelloUsersData',trelloUsersData);
  return (
    <>
      {/* <MentionsInput value={this.state.value} onChange={this.handleChange}> */}
      <div className={classes.chatFooterRoot}>
        <MentionsInput
          // markup="@__id__"
          markup="@[__display__](__id__)"
          placeholder="@Mention Member or Just message "
          value={message}
          onChange={handleChange}
          onKeyPress={(e) => {
            if (e?.key === "Enter") {
              onSendMessage();
            }
          }}
          style={defaultStyle}
          className="mentionTextbox"
        >
          <Mention
            trigger="@"
            data={(trelloUsersData?.map((val) => {
              return {
                display: val.userName,
                id: val.userName,
              }
            }
            ))}
            style={defaultMentionStyle}
            renderSuggestion={renderSuggestion}
            appendSpaceOnAdd={true}
          />
        </MentionsInput>
        <Button className={classes.iconBtnRoot} variant="contained"
          onClick={onSendMessage} style={{ backgroundColor: AppTheme.colors.primary }}>
          Send
      </Button>
      </div>
    </>
  );
};

export default ChatFooter;
