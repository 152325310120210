import React, { useEffect, useState } from 'react';
import { trelloLabels } from "theme/trelloLabels";
import hourglass from "../../../img/Hour a day.svg";
import report from "../../../img/report.svg";

import needInfo from "../../../img/need info.svg";
import createPdf from "../../../img/create pdf.svg";
import deliveryNote from "../../../img/delivery note.svg";
import partialDelivery from "../../../img/partial delivery.svg";
import revision from "../../../img/revision.svg";
import internalReview from "../../../img/internal review.svg";
import newInfoAdded from "../../../img/new info added.svg";
import onHold from "../../../img/on hold.svg";
import pc from "../../../img/pc.svg";
import ps from "../../../img/ps.svg";
import qdc from "../../../img/QDC.svg";
import qdcPlum from "../../../img/qdc plumb.svg";
import qdcMech from "../../../img/qdc mech.svg";
import qdcEle from "../../../img/qdc ele.svg";
import bc from "../../../img/bc.svg";
import blockedByTrade from "../../../img/blocked by trade.svg";
import blockedByInfo from "../../../img/blocked by info.svg";
import edit from "../../../img/edit.svg";

const ProjectLabelType = ({ labelType }) => {
    // console.log("labelType::", labelType);
    const [label, setLabel] = useState();
    useEffect(
        () => {
            if (labelType === trelloLabels.labelName.HourADay) {
                setLabel(hourglass);
            }
            //Need highlighting for Work card
            if (labelType === trelloLabels.labelName.InternalReview) {
                setLabel(internalReview);
            }
            if (labelType === trelloLabels.labelName.PartialDelivery) {
                setLabel(partialDelivery);
            }
            //Need highlighting for Work card
            if (labelType === trelloLabels.labelName.PC) {
                setLabel(pc);
            }
            if (labelType === trelloLabels.labelName.BlockedByInfo) {
                setLabel(blockedByInfo);
            }
            //Need highlighting for Work card
            if (labelType === trelloLabels.labelName.BlockedByTrade) {
                setLabel(blockedByTrade);
            }
            if (labelType === trelloLabels.labelName.OnHold) {
                setLabel(onHold);
            }
            if (labelType === trelloLabels.labelName.QDC) {
                setLabel(qdc);
            }
            if (labelType === trelloLabels.labelName.QDCElect) {
                setLabel(qdcEle);
            }
            if (labelType === trelloLabels.labelName.QDCMech) {
                setLabel(qdcMech);
            }
            if (labelType === trelloLabels.labelName.QDCPlumb) {
                setLabel(qdcPlum);
            }
            //Need highlighting for Work card
            if (labelType === trelloLabels.labelName.NewInfoAdded) {
                setLabel(newInfoAdded);
            }
            //Need highlighting for Work card
            if (labelType === trelloLabels.labelName.Revision) {
                setLabel(revision);
            }
            if (labelType === trelloLabels.labelName.BC) {
                setLabel(bc);
            }
            //Need highlighting for Work card
            if (labelType === trelloLabels.labelName.CreatePDF) {
                setLabel(createPdf);
            }
            if (labelType === trelloLabels.labelName.PS) {
                setLabel(ps);
            }
            //Need highlighting for Work card
            if (labelType === trelloLabels.labelName.DeliveryNote) {
                setLabel(deliveryNote);
            }
            //Need highlighting for Work card
            if (labelType === trelloLabels.labelName.NeededInfo) {
                setLabel(needInfo);
            }
        },
        [labelType]
    );

    return (
        <>
            {
                label !== undefined && 
                <img className="pointer label-img" src={label} alt="edit" />
            }
            {/* <p>{labelType}</p> */}
        </>
    )
}

export default ProjectLabelType;