import _ from 'lodash';
import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
// import ReactDatePicker from "@material-ui/pickers/DatePicker";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { useAsyncDebounce } from 'react-table';
import TableServices from 'services/table';
// import handle_error from '../../../utils/handle';
import DownloadArrow from '../../../assets/download_aero.svg';
import "./table.css";
import { AppTheme } from "theme/customeTheme";

function handle_error(params) {
    console.log("handle_error==", params);
}
const customStyles = {
    control: (base, state) => ({
        ...base,
        borderWidth: '1px',
        borderColor: AppTheme.colors.primary,
        borderRadius: '3px',
        boxShadow: 'none',
        "&:hover": { borderColor: 'none' },
        background: state.hasValue ? '#fbe4a3' : '',
        minHeight: "37px",
    }),
    placeholder: (base, state) => ({
        fontSize: "11px",
        color: "#90AEBB",
        fontWeight: "500",
        margin: "0 auto",
    }),
    menu: base => ({ ...base, borderRadius: 0, marginTop: 0, width: '220px' }),
    menuList: base => ({ ...base, padding: 0, whiteSpace: 'normal' }),
    valueContainer: (base) => ({ ...base, borderColor: null, marginRight: '1px', padding: 0, paddingLeft: "4px", display: "flex" }),
    indicatorSeparator: base => ({ ...base, margin: 0, width: 0 }),
    indicatorsContainer: (base) => ({ ...base, borderColor: null, boxShadow: null }),
    dropdownIndicator: (base) => ({ ...base, padding: 0, borderColor: null, boxShadow: null }),
    multiValue: (base) => ({ ...base, paddingLeft: 0, paddingRight: 0, background: 'white', }),
    multiValueRemove: (base) => ({ ...base, paddingLeft: 0, paddingRight: 0, "&:hover": { backgroundColor: 'transparent' } }),
    multiValueLabel: (base) => ({ ...base, paddingLeft: '1px', paddingRight: 0 }), //whiteSpace: "normal"
    clearIndicator: (base) => ({ ...base, paddingLeft: 0, paddingRight: 0, background: '#fbe4a3' }),
};
const CaretDownIcon = (props) => {
    return <img src={DownloadArrow} alt="chevron-down" />
};
const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon props={props} />
        </components.DropdownIndicator>
    );
};
const selectStyle = {
    borderColor: AppTheme.colors.primary,
    borderRadius: '3px',
    // border: '1px solid #90AEBB',
    // margin: '10px 0px 10px 10px',
    width: '120px',
    height: '37px',
    padding: '3px 13px',
    borderWidth: '1px',
    textAlignLast: 'center'
}

export function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}
export function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <span>
            Search:{' '}
            <input
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
                style={{
                    fontSize: '1.1rem',
                    border: '0',
                    borderRadius: '10px',

                }}
            />
        </span>
    )
}

export function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {

    const defaultRef = React.useRef()
    const resolvedRef = defaultRef

    // React.useEffect(() => {
    //     console.log("Useeffect");
    //     // setFilter(filterValue)
    //     var event = new Event('input', { bubbles: true });
    //     resolvedRef.current.onChange();
    // }, [filterValue])
    // const count = preFilteredRows.length
    // console.log("filterValue => ", filterValue);
    return (
        <input
            value={filterValue || ""}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            style={{
                ...selectStyle
            }}
        // ref={resolvedRef}
        // className="filter-box"
        // placeholder={`Search ${count} records...`}
        />
    )
}

export const getSrNo = (props) => ((props.state?.pageSize * (props.state?.pageIndex-1)) + props?.row?.index) + 1

export const customFilterForASIN = (rows, columnIds, filterValue) => {
    // console.log('calling',filterValue,rows);
    if (filterValue && filterValue != '') {
        return rows.filter(item => {
            // console.log('item.original.variant_asin_number && item.original.variant_asin_number.length',item.original.varient_asin_number);
            if (item.original.varient_asin_number?.length > 0) {
                // console.log('compare',item.original.varient_asin_number.findIndex(subitem=>subitem.variant_asin_number == filterValue));
                return item.original.varient_asin_number.findIndex(subitem => subitem.variant_asin_number.startsWith(filterValue)) != -1
            }
            return false;
        });
    }
    return rows;
}

export const customFilterForDateField = (rows, columnIds, filterValue) => {
    if (filterValue && filterValue != '') {
        return rows.filter(item => {
            console.log('debug compare', columnIds, filterValue);
            if (filterValue == 'Unassigned') {
                return item.original[columnIds] == null
            } else {
                const momentDate = moment(item.original[columnIds]);
                return momentDate.format("MMM YYYY") == filterValue;
            }

        });
    }
    return rows;
}
export const customFilterForArrayContains = (rows, columnIds, filterValue, key) => {
    if (filterValue && filterValue != '') {
        return rows.filter(row => {
            const onlyNames = _.get(row.original, columnIds[0])?.map(itm => itm[key || 'name']);
            return onlyNames.includes(filterValue)
        });
    }
    return rows;
}

// a unique option from a list
export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows && preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue || "All"}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            style={{
                ...selectStyle
            }}
            className="custom-select-dropdown"
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

// a unique option from a list
export function SelectColumnFilterInArray({
    column: { filterValue, setFilter, preFilteredRows, id },
}, key) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows && preFilteredRows.forEach(row => {
            if (Array.isArray(row.values[id]) && row.values[id].length > 0) {
                row.values[id].forEach(val => options.add(val[key || 'name']))
            }
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue || "All"}
            onChange={e => {
                setFilter(e.target.value || undefined)

            }}
            style={{
                ...selectStyle
            }}
            className="custom-select-dropdown"
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

// a unique option from a list
export function MonthYearFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        const displayOptions = new Set();
        preFilteredRows.forEach(row => {
            row.values[id] != null && options.add(moment(row.values[id]).format("YYYYMM"))
        })
        const sortedOptions = Array.from(options).sort();
        const hasUnAssigned = preFilteredRows.findIndex(row => row.values[id] == null);
        sortedOptions.forEach(opt => displayOptions.add(moment(opt, 'YYYYMM').format("MMM YYYY")))

        if (hasUnAssigned != -1)
            displayOptions.add('Unassigned')
        return [...displayOptions.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
        <select
            value={filterValue || "All"}
            onChange={e => {
                setFilter(e.target.value || undefined)

            }}
            style={{
                ...selectStyle
            }}
            className="custom-select-dropdown"
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}


// Filter for Start and End Date
export function TableDateRangeFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const inputRef = useRef();

    return (
        <ReactDatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            ref={inputRef}
            onChange={(update) => {
                setDateRange(update);
                if (update[0] && update[1]) {
                    inputRef.current.setOpen(false)
                    setFilter([
                        moment(update[0]).format('YYYY-MM-DD'),
                        update[1] ? moment(update[1]).format('YYYY-MM-DD') : null,
                    ]);
                }
                else{
                    setFilter([ null, null ]);
                }
            }}
            isClearable
            shouldCloseOnSelect={false}
            closeOnScroll={true}
            popperPlacement="auto-start"

            value={(filterValue?.[0] && filterValue?.[0] != null ? moment(filterValue[0], 'YYYY-MM-DD').format('DD-MM-YYYY') : '') + ' - '
                + (filterValue?.[1] && filterValue?.[1] != null ? moment(filterValue[1], 'YYYY-MM-DD').format('DD-MM-YYYY') : '')
            }
            className="custom-date-picker"
            wrapperClassName="custom-wrapper pr-0"
        />
    )
}


// Filter for Month only
export function TableDateInMonthFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    return (
        <ReactDatePicker
            selected={filterValue ? new Date(filterValue) : null}
            onChange={(date) => {
                if (date) {
                    setFilter(moment(date).format('YYYY-MM-DD'))
                } else {
                    setFilter(date)
                }
            }}
            dateFormat="dd MMM yyyy"
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={15}
            // yearItemNumber
            isClearable
            className="filter-box"
            placeholderText="Select Date"
            className="custom-date-picker"
            wrapperClassName="custom-wrapper pr-0"
        />
    )
}

//doFilter Method
export const doFilter = (filters, filterMap) => {
    const filtersToPass = {};
    filters?.length > 0 && filters.forEach(filter => {
        if (filterMap[filter?.id] && Array.isArray(filterMap[filter?.id])) {
            const fieldsArr = filterMap[filter?.id];
            filtersToPass[fieldsArr[0]] = filter?.value[0];
            filtersToPass[fieldsArr[1]] = filter?.value[1];
        } else if (filterMap[filter?.id]) {
            if (Array.isArray(filter?.value)) {
                const filterValues = filter?.value?.map(itm => itm.value)
                filtersToPass[filterMap[filter?.id]] = filterValues

            } else
                filtersToPass[filterMap[filter?.id]] = filter?.value?.trim()
        }
        else alert('missting entry ' + filter?.id)
    })
    return filtersToPass;
}


//Video Types
const getVideoTypes = async () => {
    // const videoTypeResponse = await ajax(CONSTANTS.API_METHODS.GET, CONSTANTS.API.VIDEO_TYPE);
    // return new Promise((resolve, reject) => {
    //     if (videoTypeResponse.status === CONSTANTS.STATUS.OK) {
    //         const videoTypeData = videoTypeResponse.data.data.map((value) => ({ value: value.name, label: value.name }));
    //         resolve(videoTypeData);
    //     } else if (videoTypeResponse.status === CONSTANTS.STATUS.DATA_NOT_FOUND) {
    //         resolve([]);
    //     } else {
    //         handle_error(videoTypeResponse.status);
    //         reject(videoTypeResponse)
    //     }
    // });
}
//Product Categories
const getProductCategories = async () => {
    // const productCategoryResponse = await ajax(CONSTANTS.API_METHODS.GET, CONSTANTS.API.PRODUCT_CATEGORY);
    // return new Promise((resolve, reject) => {
    //     if (productCategoryResponse.status === CONSTANTS.STATUS.OK) {
    //         const productCategoryData = productCategoryResponse.data.data.map((value) => ({ value: value.name, label: value.name }));
    //         resolve(productCategoryData);
    //     } else if (productCategoryResponse.status === CONSTANTS.STATUS.DATA_NOT_FOUND) {
    //         resolve([]);
    //     } else {
    //         handle_error(productCategoryResponse.status);
    //         reject(productCategoryResponse)
    //     }
    // });
}
//Product Sub Categories
const getProductSubCategories = async () => {
    // const productSubCategoryResponse = await ajax(CONSTANTS.API_METHODS.GET, CONSTANTS.API.PRODUCT_SUB_CATEGORY);
    // return new Promise((resolve, reject) => {
    //     if (productSubCategoryResponse.status === CONSTANTS.STATUS.OK) {
    //         const productSubCategoryData = productSubCategoryResponse.data.data.map((value) => ({ value: value.name, label: value.name }));
    //         resolve(productSubCategoryData);
    //     } else if (productSubCategoryResponse.status === CONSTANTS.STATUS.DATA_NOT_FOUND) {
    //         resolve([]);
    //     } else {
    //         handle_error(productSubCategoryResponse.status);
    //         reject(productSubCategoryResponse)
    //     }
    // });
}
//Video Categories
const getVideoCategories = async () => {
    // const videoCategoryResponse = await ajax(CONSTANTS.API_METHODS.GET, CONSTANTS.API.VIDEO_FORMAT_CATEGORY);
    // return new Promise((resolve, reject) => {
    //     if (videoCategoryResponse.status === CONSTANTS.STATUS.OK) {
    //         const videoCategoryData = videoCategoryResponse.data.data.map((value) => ({ value: value.name, label: value.name }));
    //         resolve(videoCategoryData);
    //     } else if (videoCategoryResponse.status === CONSTANTS.STATUS.DATA_NOT_FOUND) {
    //         resolve([]);
    //     } else {
    //         handle_error(videoCategoryResponse.status);
    //         reject(videoCategoryResponse)
    //     }
    // });
}
//Video Sub Categories
const getVideoSubCategories = async () => {
    // const videoSubCategoryResponse = await ajax(CONSTANTS.API_METHODS.GET, CONSTANTS.API.VIDEO_FORMAT_SUB_CATEGORY);
    // return new Promise((resolve, reject) => {
    //     if (videoSubCategoryResponse.status === CONSTANTS.STATUS.OK) {
    //         const videoSubCategoryData = videoSubCategoryResponse.data.data.map((value) => ({ value: value.name, label: value.name }));
    //         resolve(videoSubCategoryData);
    //     } else if (videoSubCategoryResponse.status === CONSTANTS.STATUS.DATA_NOT_FOUND) {
    //         resolve([]);
    //     } else {
    //         handle_error(videoSubCategoryResponse.status);
    //         reject(videoSubCategoryResponse)
    //     }
    // });
}
//Video Sub Categories
const getAttributeConfigData = async () => {
    // const attributeConfigResponse = await ajax(CONSTANTS.API_METHODS.GET, CONSTANTS.API.ATTRIBUTE_CONFIGURATION);
    // return new Promise((resolve, reject) => {
    //     if (attributeConfigResponse.status === CONSTANTS.STATUS.OK) {
    //         const attributeCodeData = attributeConfigResponse.data.data.map((value) => ({ id: value.id, value: value.attribute_code, label: value.attribute_code }));
    //         console.log('debug attributeCodeData', attributeCodeData);
    //         resolve(attributeCodeData);
    //     } else if (attributeConfigResponse.status === CONSTANTS.STATUS.DATA_NOT_FOUND) {
    //         resolve([]);
    //     } else {
    //         handle_error(attributeConfigResponse.status);
    //         reject(attributeConfigResponse)
    //     }
    // });
}

//Get Users
const getUsers = async (userRole, needUserCodeAsValue) => {
    // const userResponse = await ajax(CONSTANTS.API_METHODS.GET, CONSTANTS.API.ALL_USER);

    // return new Promise((resolve, reject) => {
    //     const usersDate = [];
    //     if (userResponse.status === CONSTANTS.STATUS.OK) {
    //         userResponse.data.data.forEach((x) => {
    //             if (x.role_code === userRole) usersDate.push({ label: x.name, value: needUserCodeAsValue ? x.code : x.name });
    //         });
    //         resolve(usersDate);
    //     } else if (userResponse.status === CONSTANTS.STATUS.DATA_NOT_FOUND) {
    //         resolve([]);
    //     } else {
    //         handle_error(userResponse.status);
    //         reject(userResponse)
    //     }
    // });
}
// //Get Locations
const getLocations = async () => {
    //     const locationResponse = await ajax(CONSTANTS.API_METHODS.GET, CONSTANTS.API.LOCATION);

    //     return new Promise((resolve, reject) => {
    //         if (locationResponse.status === CONSTANTS.STATUS.OK) {
    //             const locationData = locationResponse.data.data.map((value) => ({ id: value.id, value: value.name, label: value.name }));
    //             resolve(locationData);
    //         } else if (locationResponse.status === CONSTANTS.STATUS.DATA_NOT_FOUND) {
    //             resolve([]);
    //         } else {
    //             handle_error(locationResponse.status);
    //             reject(locationResponse)
    //         }
    //     });
}

// Video Category Filter
export function VideoTypeFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const [options, setOptions] = useState([]);

    React.useMemo(() => {
        getVideoTypes().then(videoTypes => setOptions(videoTypes))
    }, [])

    // Render a multi-select box
    return (
        <Select
            isMulti
            value={filterValue || []}
            options={options}
            placeholder="Video Type"
            styles={customStyles}
            maxMenuHeight={200}
            onChange={(xx) => {
                setFilter(xx)
            }}
            components={{ DropdownIndicator }}
        />
    )
}

// Number Range Filter
export function NumberRangeFilter({
    column: { filterValue, setFilter },
}) {

    const [hourRange, setHourRange] = useState([null, null]);
    const [startPoint, endPoint] = hourRange;
    const [startNumber, setStartNumber,] = useState(0);
    const [endNumber, setEndNumber,] = useState(0);
    const setClear = () => {
        console.log('setClear');
        setFilter(null);
        let clearHour = [null, null];
        setHourRange(clearHour);
    }
    return (
        <div>
            <div style={{ display: "flex" }}>
                <input
                    value={startPoint || ''}
                    type="number"
                    onChange={event => {
                        let selectedOptions = (event.target.value);
                        let selectedMinHour = [parseFloat(selectedOptions), endNumber];
                        setStartNumber(parseFloat(selectedOptions))
                        setHourRange(selectedMinHour);
                    }}
                    style={{
                        width: "50px",
                        height: "25px",
                        borderColor: AppTheme.colors.primary,
                        borderWidth: '1px',
                        borderRadius: '3px',
                        marginRight: "0.5rem",
                        padding: "5px"
                    }}
                />
        to
            <input
                    value={endPoint || ''}
                    type="number"
                    onChange={e => {
                        let selectedOptions = (e.target.value);
                        let selectedMinHour = [startNumber ? startNumber : 0, parseFloat(selectedOptions)];
                        setEndNumber(parseFloat(selectedOptions))
                        setHourRange(selectedMinHour);
                    }}
                    style={{
                        width: "50px",
                        height: "25px",
                        borderColor: AppTheme.colors.primary,
                        borderWidth: '1px',
                        borderRadius: '3px',
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem",
                        padding: "5px"
                    }}
                />
            </div>
            <div style={{ display: "flex" }}>
                <button style={{ width: "60px", marginTop: "5px", marginRight: "10px" }} disabled={endNumber > 0 ? false : true} onClick={(e) => setFilter(hourRange)}>click</button>
                {/* <button style={{ width: "60px", marginTop: "5px", marginRight: "10px" }} onClick={(e) => setFilter(null)}>clear</button> */}
                <button style={{ width: "60px", marginTop: "5px", marginRight: "10px" }} onClick={(e) => setClear()}>clear</button>
            </div>
        </div >
    )
}
// Project Labels MultiSelect Filter
export function ProjectLabelFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const dispatch = useDispatch();
    const { allProjectLabels } = useSelector(({ table }) => table);
    React.useEffect(() => {
        dispatch(TableServices.getAllProjectLabel());
    }, [])
    // Render a multi-select box
    return (
        <Select
            isMulti
            value={filterValue || []}
            options={allProjectLabels}
            placeholder="Highlighter.."
            styles={customStyles}
            maxMenuHeight={200}
            onChange={(xx) => {
                setFilter(xx)
            }}
            components={{ DropdownIndicator }}
        />
    )
}
// Work Card Labels MultiSelect Filter
export function WorkCardLabelFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const dispatch = useDispatch();
    const { allWorkCardLabels } = useSelector(({ table }) => table);
    React.useEffect(() => {
        dispatch(TableServices.getAllWorkCardLabel());
    }, [])
    // Render a multi-select box
    return (
        <Select
            isMulti
            value={filterValue || []}
            options={allWorkCardLabels}
            placeholder="Highlighter.."
            styles={customStyles}
            maxMenuHeight={200}
            onChange={(xx) => {
                setFilter(xx)
            }}
            components={{ DropdownIndicator }}
        />
    )
}
// Folder MultiSelect Filter
export function FoldersFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const dispatch = useDispatch();
    const { folderData } = useSelector(({ table }) => table);
    React.useEffect(() => {
        dispatch(TableServices.getFoldersData());
    }, [])
    // Render a multi-select box
    return (
        <Select
            isMulti
            value={filterValue || []}
            options={folderData}
            placeholder="Folders.."
            styles={customStyles}
            maxMenuHeight={200}
            onChange={(xx) => {
                setFilter(xx)
            }}
            components={{ DropdownIndicator }}
        />
    )
}
// Managers MultiSelect Filter
export function ManagersFilter({
    column: { filterValue, setFilter },
}) {
    const dispatch = useDispatch();
    const { managerData } = useSelector(({ table }) => table);

    // Render a multi-select box
    return (
        <Select
            isMulti
            value={filterValue || []}
            options={managerData}
            placeholder="Select.."
            styles={customStyles}
            maxMenuHeight={200}
            onChange={(xx) => {
                setFilter(xx)
            }}
            components={{ DropdownIndicator }}
        />
    )
}
// Product Sub Categories Filter
export function ProductSubCategoryFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const [options, setOptions] = useState([]);

    React.useMemo(() => {
        getProductSubCategories().then(productSubCats => setOptions(productSubCats))
    }, [])

    // Render a multi-select box
    return (
        <Select
            isMulti
            value={filterValue || []}
            options={options}
            placeholder="Prod. Sub-Cat."
            styles={customStyles}
            maxMenuHeight={200}
            onChange={(xx) => {
                setFilter(xx)
            }}
            components={{ DropdownIndicator }}
        />
    )
}
// Video Categories Filter
export function VideoCategoryFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const [options, setOptions] = useState([]);

    React.useMemo(() => {
        getVideoCategories().then(videoCats => setOptions(videoCats))
    }, [])

    // Render a multi-select box
    return (
        <Select
            isMulti
            value={filterValue || []}
            options={options}
            placeholder="Video Cat."
            styles={customStyles}
            maxMenuHeight={200}
            onChange={(xx) => {
                setFilter(xx)
            }}
            components={{ DropdownIndicator }}
        />
    )
}
// Video Sub Categories Filter
export function VideoSubCategoryFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const [options, setOptions] = useState([]);

    React.useMemo(() => {
        getVideoSubCategories().then(videoSubCats => setOptions(videoSubCats))
    }, [])

    // Render a multi-select box
    return (
        <Select
            isMulti
            value={filterValue || []}
            options={options}
            placeholder="Video Sub-Ca."
            styles={customStyles}
            maxMenuHeight={200}
            onChange={(xx) => {
                setFilter(xx)
            }}
            components={{ DropdownIndicator }}
        />
    )
}
// Attribute Configuration Filter
export function AttributeConfigurationFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const [options, setOptions] = useState([]);

    React.useMemo(() => {
        getAttributeConfigData().then(attributConfig => setOptions(attributConfig))
    }, [])

    // Render a multi-select box
    return (
        <Select
            isMulti
            value={filterValue || []}
            options={options}
            placeholder="Attr. Confi."
            styles={customStyles}
            maxMenuHeight={200}
            onChange={(xx) => {
                setFilter(xx)
            }}
            components={{ DropdownIndicator }}
        />
    )
}

// Users Filter
export function UsersFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}, userRole,
    placeholder,
    needCapitalWithoutSpace) {
    const [options, setOptions] = useState([]);

    React.useMemo(() => {
        getUsers(userRole, needCapitalWithoutSpace).then(opts => setOptions(opts))
    }, [])

    // Render a multi-select box
    return (
        <Select
            isMulti
            value={filterValue || []}
            options={options}
            placeholder={placeholder}
            styles={customStyles}
            maxMenuHeight={200}
            onChange={(xx) => {
                setFilter(xx)
            }}
            components={{ DropdownIndicator }}
        />
    )
}

// Locations Filter
export function LocationsFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}, placeholder) {
    const [options, setOptions] = useState([]);

    React.useMemo(() => {
        getLocations().then(opts => setOptions(opts))
    }, [])

    // Render a multi-select box
    return (
        <Select
            isMulti
            value={filterValue || []}
            options={options}
            placeholder={placeholder}
            styles={customStyles}
            maxMenuHeight={200}
            onChange={(xx) => {
                setFilter(xx)
            }}
            components={{ DropdownIndicator }}
        />
    )
}




// static options filter
export function StaticOptionsFilter({
    column: { filterValue, setFilter },
}, options) {

    // Render a multi-select box
    return (
        <select
            value={filterValue || "All"}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            style={{
                ...selectStyle
            }}
            className="custom-select-dropdown"
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

// static options filter object based
export function StaticOptionsKeyValueFilter({
    column: { filterValue, setFilter },
}, options) {

    // Render a multi-select box
    return (
        <select
            value={filterValue || "All"}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            style={{
                ...selectStyle
            }}
            className="custom-select-dropdown"
        >
            <option value="">Select</option>
            {options.map((option, i) => (
                <option key={i} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    )
}