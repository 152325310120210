export const AppTheme = {
    colors: {
        primary: "#006D8F",
        white: '#FFFFFF',
        black: '#000000'
    },
    CustomLabelcolors: {
        green: "#58B000",
        red: '#FF0000',
        yellow: '#FFB905',
    },
    BadgeBackgroundColors: {
        pink: "#FFF5FB",
        orange: "#FFDBC0",
        green: '#80EBB1',
    },
    BadgeBorderColors: {
        pink: "#FFABDE",
        orange: "#FF783F",
        green: '#7CE3BE',
    }
}