import React, { useEffect, useState } from 'react';
import { AppTheme } from "theme/customeTheme";

const CustomBadge = ({ name, labelStatus, cardId }) => {

    const [labelBackgroundColor, setLabelBackgroundColor] = useState();
    const [labelBorderColor, setLabelBorderColor] = useState();
    useEffect(
        () => {
            switch (labelStatus) {
                case "Pending":
                    setLabelBackgroundColor(AppTheme.BadgeBackgroundColors.pink);
                    setLabelBorderColor(AppTheme.BadgeBorderColors.pink);
                    break;
                case "In progress":
                    setLabelBackgroundColor(AppTheme.BadgeBackgroundColors.orange);
                    setLabelBorderColor(AppTheme.BadgeBorderColors.orange);
                    break;
                case "Completed (PM)":
                    setLabelBackgroundColor(AppTheme.BadgeBackgroundColors.green);
                    setLabelBorderColor(AppTheme.BadgeBorderColors.green);
                    break;
                default:
                    setLabelBackgroundColor("#ffffff");
                    setLabelBorderColor("#ffffff");
                    break;
            }
        },
        [labelStatus]
    );
    return (
        <p title={name} className="text-truncate highest-priorities"
            style={{
                backgroundColor: labelBackgroundColor,
                borderColor: labelBorderColor,

            }}><a style={{color:'black'}} href={'https://trello.com/c/'+cardId} target="_blank" rel="noreferrer">{name}</a></p>
    )
}

export default CustomBadge;