import ReactTableHideShowColumns from '@jumbo/components/TableComponents/react-table-hide-show';
import ReactTableComponent from '@jumbo/components/TableComponents/ReactTable';
import { doFilter, usePrevious } from '@jumbo/components/TableComponents/table-utils';
import TableActions from '@jumbo/components/TableComponents/TableActions';
import { Box, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableServices from 'services/table';
import io from "socket.io-client";
import { BASE_API_URL, BASE_WS_URL } from '../../../utils/globals';
import { staticTableColumns } from './staticColumns';
import CommentsDialogBox from '../../../@jumbo/components/TableComponents/Comments/CommentsDialogBox';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

const filterMap = {
  "card_title": "name",
  // "labelNames": "LabelNames",
  "PrelimDue": "PrelimDue",
  "FinalDue": "FinalDue",
  "PromisedDate": "PromisedDate",
  "Promise": "Promise",
  "Folder": "folder",
  "Estimated_Mech_Hours": ["minMech", "maxMech"],
  "Mechanical_Last_Member": "mechLast",
  "Manager_Innitial_Mechanical": "mechInitials",
  "Estimated_Elec_Hours": ["minElec", "maxElec"],
  "Electrical_Last_Member": "elecLast",
  "Manager_Innitial_Electrical": "elecInitials",
  "Estimated_Plum_Hours": ["minPlum", "maxPlum"],
  "Plumbing_Last_Member": "plumLast",
  "Manager_Innitial_Plumbing": "plumInitials"
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2FAFD",
    padding: "16px 16px 0 16px",
    borderRadius: 0,
    borderTopRightRadius: 10,
  },
}));
const Table = () => {
  const classes = useStyles();
  const ITEMS_PER_PAGE = 10;
  const dispatch = useDispatch()
  const { tableData } = useSelector(({ table }) => table);
  const [socket, setSocket] = useState(null);
  const [data, setData] = useState([]);
  const [scrollableTableHiddenColumns, setScrollableTableHiddenColumns] = React.useState([]);
  const tableRef = useRef(null);
  const [tableColumns, setTableColumns] = useState([]);
  const [fixedTableHiddenColumns, setFixedTableHiddenColumns] = React.useState([]);
  const [lastLockedIndex, setLastLockedIndex] = useState(0);
  const [orderBy, setOrderBy] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [selectedRows, setSelectedRows] = React.useState([])
  const [filtersToApply, setFiltersToApply] = React.useState({})
  const prevFiltersToApply = usePrevious(filtersToApply)
  const mountedRef = useRef()

  const [paginationOptions, setPaginationOptions] = useState({
    limit: ITEMS_PER_PAGE,
    offset: 0,
    currentPage: 0,
  });
  const setRowsForAction = (rows) => {
    setSelectedRows(rows);
  }
  const getPageData = (pageNo, itemsPerPage) => {
    setPaginationOptions({
      limit: itemsPerPage,
      offset: (pageNo) * itemsPerPage,
      currentPage: pageNo
    });
  }
  const setHideColumn = (column, flag) => {
    var hiddenColumns = [...fixedTableHiddenColumns, ...scrollableTableHiddenColumns];
    var newHiddenColumns = [];
    if (flag) {
      newHiddenColumns = hiddenColumns.filter(col => col !== column.accessor)
    } else {
      newHiddenColumns = [...hiddenColumns, column.accessor]
    }
    setScrollableTableHiddenColumns(newHiddenColumns)
    tableRef.current.hideReactTableColumns(newHiddenColumns)
  }
  const setColumnLocks = (lock, column) => {
    let tableCurrentCols = [...tableColumns];

    const findSelected = tableCurrentCols.find(itm => itm.accessor === column);
    findSelected.sticky = lock ? 'left' : '';
    tableCurrentCols = [...tableColumns].filter(itm => itm.accessor !== column);
    if (lock)
      tableCurrentCols.splice(lastLockedIndex, 0, findSelected);
    else
      tableCurrentCols.push(findSelected)
    setTableColumns([...tableCurrentCols]);
    if (lock)
      setLastLockedIndex(lastLockedIndex + 1);
    else
      setLastLockedIndex(lastLockedIndex - 1);
    const onlyColAccessors = tableCurrentCols.map(col => col.accessor);
    //set react-table lock cols
    tableRef.current.setReactTableCols(onlyColAccessors)
  }
  const setOrderByColumns = (column) => {
    setPaginationOptions({
      limit: ITEMS_PER_PAGE,
      offset: (0) * ITEMS_PER_PAGE,
      currentPage: 0
    });
    setOrderBy(column);
    if (orderBy == column) {
      if (orderType == "asc") {
        getTableData(column, "desc");
        setOrderType("desc");
      }
      if (orderType == "desc") {
        getTableData(column, "asc");
        setOrderType("asc");
      }
    }
    else {
      getTableData(column, "asc");
      setOrderType("asc");
    }
  }
  const getTableData = async (orderBy, orderType) => {
    let limit = paginationOptions.limit;
    let currentPage = paginationOptions.currentPage || null;
    let orderByColumn = orderBy || null;
    let columnOrderType = orderType || null;
    dispatch(TableServices.getTableData({ limit, currentPage, orderByColumn, columnOrderType, ...filtersToApply }));
  }
  const shouldDisableRow = (row) => {
    return false
  }
  const sortBy = (value) => {
    getTableData(value);
  };
  const setTestData = (row, flag) => {

  }
  const applyFilters = (filters) => {
    setFiltersToApply(doFilter(filters, filterMap))
  }
  useEffect(() => {
    if (filtersToApply && prevFiltersToApply &&
      !_.isEqual(
        prevFiltersToApply,
        filtersToApply,
      )
    ) {
      setPaginationOptions({
        limit: ITEMS_PER_PAGE,
        offset: (0) * ITEMS_PER_PAGE,
        currentPage: 0
      });
      getTableData()
    }
  }, [filtersToApply, prevFiltersToApply])
  useEffect(() => {
    if (paginationOptions.limit) {
      if (mountedRef.current) {  // ← the trick
        getTableData(orderBy, orderType);
      }
    }
  }, [paginationOptions.currentPage, paginationOptions.limit, paginationOptions.offset]);
  useEffect(() => {
    if (tableData?.trelloCards?.length > 0) {
      setData(tableData?.trelloCards)
    } else {
      setData([])
    }
  }, [tableData])

  useEffect(() => {
    dispatch(TableServices.getManagersData());
    setTableColumns([...staticTableColumns]);
    getTableData();
    const newSocket = io(BASE_WS_URL);
    setSocket(newSocket);
    mountedRef.current = true
    console.log("socket 1");
    return () => newSocket.close();
  }, []);
  useEffect(() => {
    if (socket && data?.length > 0) {
      console.log("socket 2");
      const deleteMessageListener = (messageID) => {
        console.log("deleteMessageListener", messageID);
      };

      socket.on("onTrelloCardUpdate", (res) => {
        console.log("socket update here");
        const UpdatedData = JSON.parse(res)
        console.log("socket 3");
        const findIndex = data?.findIndex((item) => item.trello_id === UpdatedData?.trello_id)
        if (findIndex !== -1) {
          setData(oldData => {
            return oldData.map((item) => {
              if (item.trello_id === UpdatedData?.trello_id) {
                return UpdatedData
              }
              return item
            })
          })
        } else {
          return null
        }
      });
      // return () => {
      //   socket.off('onTrelloCardUpdate', deleteMessageListener);
      // };
    }
    console.log("socket 4");
  }, [socket, data]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <title> Priority Sheets | Report</title>
      </Helmet>
      <Box display="inline-flex" alignItems="center" className="tab-heading" >Active Projects</Box>
      <div >
        <Box display="flex" alignItems="center" className={classes.root}>
          <TableActions sortBy={(value) => sortBy(value)} />
          <ReactTableHideShowColumns
            columns={[...staticTableColumns]}
            setHideColumn={(column, flag) => setHideColumn(column, flag)}
            scrollableTableHiddenColumns={scrollableTableHiddenColumns}
          />
        </Box>
        <div className="text-center">
          <ReactTableComponent
            columns={[...tableColumns] || []}
            hiddenColumns={scrollableTableHiddenColumns}
            hideColumns={setScrollableTableHiddenColumns}
            lockColumns={setColumnLocks}
            orderByColumns={setOrderByColumns}
            overflow='auto'
            data={{
              "count": tableData?.totalItems,
              "data": data
            }}
            ref={tableRef}
            isCheckBox={true}
            lastLockedIndex={lastLockedIndex}
            setRowsForAction={setRowsForAction}
            setTestData={setTestData}
            isDataLoaded={true}
            getPageData={getPageData}
            itemsPerPage={paginationOptions.limit}
            currentPage={paginationOptions.currentPage}
            applyFilters={applyFilters}//~FILTERS
            shouldDisableRow={shouldDisableRow}
          />
        </div>
      </div>
    </>
  )
}

export default Table
