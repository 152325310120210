import React, { useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Popover,
  Tooltip,
  useTheme
} from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
// import NotificationsIcon from "../../../../../../../public/images/notification.png";
import CmtCardHeader from "../../../../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../../../../@coremat/CmtCard/CmtCardContent";
import CmtList from "../../../../../../@coremat/CmtList";
import CmtCard from "../../../../../../@coremat/CmtCard";

// import { getCustomDateTime, getNewDate } from '../../../@jumbo/utils/dateHelper';
import NotificationItem from "./NotificationItem";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import { getCustomDateTime, getNewDate } from "@jumbo/utils/dateHelper";
import CmtAvatar from "@coremat/CmtAvatar";
import GridContainer from "@jumbo/components/GridContainer";
import { useDispatch, useSelector } from "react-redux";
import DashboardServices from "services/dashboard";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    "& .Cmt-header-root": {
      paddingTop: 4,
      paddingBottom: 4,
      backgroundColor: "#006D8F",
    },
    "& .Cmt-header-content .Cmt-title": {
      color: "#ffffff !important",
      fontSize: 25,
      fontFamily: "poppins",
      fontWeight: 600,
      padding: "5px 0px"
    },
    "& .Cmt-card-content": {
      padding: "7px 16px 7px 16px !important"
    }
  },
  typography: {
    padding: theme.spacing(2)
  },
  iconRoot: {
    position: "relative",
    color: alpha(theme.palette.common.white, 0.38),
    "&:hover, &.active": {
      color: theme.palette.common.white
    },
    marginTop: 20,
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.warning.main,
    width: 20
  },
  scrollbarRoot: {
    height: 300,
    padding: 16
  },
  popoverRoot: {
    "& .MuiPopover-paper": {
      width: 450,
      borderRadius: "15px 15px 0px 0px"
    }
  }
}));

const actions = [
  {
    label: "More Detail"
  },
  {
    label: "Close"
  }
];

export const intranet = {
  headerNotifications: [
    {
      id: 1,
      name: 'New task assigned to you',
      time: '1 hour ago',
      icons: "/images/assignment.png"
    },
    {
      id: 1,
      name: "New project added",
      time: '3 hour ago',
      icons: "/images/new project.png"
    },
    {
      id: 1,
      name: "Mentioned you in a comment",
      time: '10 mins ago',
      icons: "/images/mention.png"
    },
    {
      id: 1,
      name: "2 projects completed ",
      time: '1 hour ago',
      icons: "/images/completed.png"
    },
  ]
};


// const headerNotifications = [];

const HeaderNotifications = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [counter, setCounter] = React.useState(0);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { notificationData } = useSelector(({ dashboard }) => dashboard);
  const { headerNotifications } = intranet;

  React.useEffect(() => {
    dispatch(DashboardServices.getNotificationData());
  }, [])
  const onOpenPopOver = event => {
    setAnchorEl(event.currentTarget);
    setCounter(0);
  };
  const onClosePopOver = () => {
    setAnchorEl(null);
    setCounter(notificationData?.totalItems);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    setCounter(notificationData?.totalItems)
  }, [notificationData?.totalItems])

  return (
    <Box display="flex" alignItems="center" pr={2}>
      <Tooltip title="Notifications">
        <IconButton
          onClick={onOpenPopOver}
          className={clsx(classes.iconRoot, "Cmt-appIcon", {
            active: counter > 0
          })}
        >
          <Badge badgeContent={counter} classes={{ badge: classes.counterRoot }}>
            {/* <NotificationsIcon /> */}
            <img src="/images/notification.png" className={classes.loginSideImg2} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Box display="flex" alignItems="center" paddingTop="15px">
        <Typography
          component="h3"
          variant="h6"
          style={{
            color: "#000000",
            fontSize: 20,
            paddingRight: 10
          }}
        >
          | {authUser?.fullName}
        </Typography>
        <CmtAvatar size={35} />
      </Box>
      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="Notifications"
            actionsPos="top-corner"
            // actions={actions}
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: "solid"
            }}
          />
          <CmtCardContent>
            {notificationData?.notifications?.length > 0 ? (
              // <PerfectScrollbar className={classes.scrollbarRoot}>
              <CmtList
                data={notificationData?.notifications}
                renderRow={(item, index) => (
                  <NotificationItem key={index} item={item} />
                )}
              />
              // </PerfectScrollbar>
            ) : (
              <Box p={6}>
                <Typography variant="body2">No notifications found</Typography>
              </Box>
            )}
          </CmtCardContent>
        </CmtCard>
      </Popover>
    </Box>
  );
};

export default HeaderNotifications;
