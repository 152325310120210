import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { styled, withStyles } from '@material-ui/styles';
import React from 'react';
import { setUpdatedChatConversation } from 'redux/actions/Table.action';
import { AppTheme } from 'theme/customeTheme';
import CommentsDialogBox from './Comments/CommentsDialogBox';
const options = [
    {
        label: "Mechanical"
    },
    {
        label: "Electrical"
    },
    {
        label: "Plumbing"
    },
    {
        label: "General"
    },
    {
        label: "All"
    },
];
const StyledMenu = styled((props) => (
    <Popover
        elevation={0}
        {...props}
    />
))(({ }) => ({
    '& .MuiPaper-root': {
        boxShadow:
            '0px 4px 4px rgba(0, 0, 0, 0.25);',
    },
}));
const CustomMenuItem = withStyles({
    root: {
        fontFamily: '"Mulish", sans-serif',
        color: "#000000",
        fontSize: 16,
        padding: "10px 15px",
        // borderRadius: 5,
        // marginBottom: 4,
        letterSpacing: 0.2,
        minHeight: "auto",
        fontWeight: 600,
        '&:hover': {
            backgroundColor: AppTheme.colors.primary,
            color: "#FFFFFF"
            // borderRadius: 5,
        }
    }
})(MenuItem)
const CommentPopover = ({ projectInfo }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [commentSection, setcommentSection] = React.useState(null);
    // console.log("projectInfo:;", projectInfo.threads);
    const handleOpenDialog = (chatInfo) => {
        setOpenDialog(true);
        setcommentSection(chatInfo);
    };
    const handleCloseDialog = () => {
        setUpdatedChatConversation({});
                setOpenDialog(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <img src="/images/comments.svg" alt="comments" onClick={handleClick} width="50px" className="pointer" />
            {/* <IconButton aria-label="arrow-dropdown" className="arrow-dropdown-icon" size="small" onClick={handleClick}>
                <ArrowDropDown />
            </IconButton> */}
            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}

            >
                <div className="actions-menu p-2">
                    {projectInfo?.threads?.map((item, i) => {
                        // console.log("item::", item);
                        return (
                            <CustomMenuItem
                                onClick={() => handleOpenDialog(item)}
                                key={'CustomMenuItem'+i}
                            >{item.thread_name}</CustomMenuItem>
                        )
                    })}
                </div>
            </StyledMenu>
            <CommentsDialogBox commentSection = {commentSection} projectInfo={projectInfo} openDialog={openDialog} handleOpenDialog={handleOpenDialog} handleCloseDialog={handleCloseDialog} />
        </div>
    )
}
export default CommentPopover;