import {
    STORE_DASHBOARD_DATA,
    STORE_NOTIFICATION_DATA
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
    dashboardData: [],
    notificationData: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case STORE_DASHBOARD_DATA: {
            return {
                ...state,
                dashboardData: action.payload
            };
        }
        case STORE_NOTIFICATION_DATA: {
            return {
                ...state,
                notificationData: action.payload
            };
        }
        default:
            return state;
    }
};
