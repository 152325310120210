import CommentPopover from '@jumbo/components/TableComponents/CommentPopover'
import CustomBadge from '@jumbo/components/TableComponents/CustomBadge'
import CustomInfo from '@jumbo/components/TableComponents/CustomInfo'
import CustomLabel from '@jumbo/components/TableComponents/CustomLabel'
import HoursSpentPopover from '@jumbo/components/TableComponents/HoursSpentPopover'
import LabelHighlighters from '@jumbo/components/TableComponents/LabelHighlighters'
import ReactTableHideShowColumns from '@jumbo/components/TableComponents/react-table-hide-show'
import { TableDateRangeFilter } from '@jumbo/components/TableComponents/table-utils'
import { doFilter, FoldersFilter, getSrNo, ManagersFilter, NumberRangeFilter, StaticOptionsKeyValueFilter, TableDateInMonthFilter, usePrevious } from '@jumbo/components/TableComponents/table-utils'
import TableActions from '@jumbo/components/TableComponents/TableActions'
import TableEditPopup from '@jumbo/components/TableComponents/TableEditPopup/TableEditPopup'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { UpdateTableRow } from 'redux/actions/Table.action'
import TableServices from 'services/table'
import { io } from 'socket.io-client'
import { BASE_WS_URL } from 'utils/globals'
import ReactMainTable from './MainTable'
import LegendIconList from './LegendIconList'
import { Dashboard, Toc, PostAdd } from "@material-ui/icons";
import DeliveryDateComponent from '@jumbo/components/TableComponents/DeliveryDateComponent'
import { ProjectLabelFilter } from '@jumbo/components/TableComponents/table-utils'
import WorkCardLabel from '@jumbo/components/TableComponents/WorkCardLabel'
import { WorkCardLabelFilter } from '@jumbo/components/TableComponents/table-utils'
const filterMap = {
    "card_title": "name",
    // "PrelimDue": ["PrelimStartDue","PrelimEndDue"],
    // "FinalDue": ["FinalStartDue","FinalEndDue"],
    "DeliveryDate": ["DeliveryStartDate", "DeliveryEndDate"],
    "PromisedDate": "PromisedDate",
    "Promise": "Promise",
    "labelNames": "LabelNames",
    "Folder": "folder",
    "Estimated_Mech_Hours": ["minMech", "maxMech"],
    "MechWorkCardLabels": "MechWorkCardLabels",
    "Mechanical_Last_Member": "mechLast",
    "Manager_Innitial_Mechanical": "mechInitials",
    "Estimated_Elec_Hours": ["minElec", "maxElec"],
    "ElectWorkCardLabels": "ElectWorkCardLabels",
    "Electrical_Last_Member": "elecLast",
    "Manager_Innitial_Electrical": "elecInitials",
    "Estimated_Plum_Hours": ["minPlum", "maxPlum"],
    "PlumWorkCardLabels" : "PlumWorkCardLabels",
    "Plumbing_Last_Member": "plumLast",
    "Manager_Innitial_Plumbing": "plumInitials",
    "Total_Spent_Hours": ["minTimeSpent", "maxTimeSpent"],
}
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F2FAFD",
        padding: "16px 16px 0 16px",
        borderRadius: 0,
        borderTopRightRadius: 10,
    },
}));
function ReactTable() {
    const columns = React.useMemo(
        () => [
            {
                "Header": "Sr.",
                "accessor": "Sr_NO",
                Cell: (props) => {
                    return (
                        <div className="table_srNo">
                            <span className="table_index">{getSrNo(props)}</span>
                            <TableEditPopup editData={props} trelloCardId={props?.cell?.row?.original?.trello_id} />
                        </div>
                    )
                },
                disableFilters: true,
            },
            {
                id: 'DeliveryDate',
                "Header": "Delivery Date",
                accessor: (originalRow, rowIndex) => {
                    // return originalRow?.PromisedDate
                    return <DeliveryDateComponent deliveryDates={originalRow} />
                },
                Filter: TableDateRangeFilter
            },
            {
                id: 'PromisedDate',
                "Header": "Promise Date",
                accessor: (originalRow, rowIndex) => {
                    return originalRow?.PromisedDate
                },

            },
            {
                id: 'Promise',
                "Header": "Promise",
                Filter: (props) => StaticOptionsKeyValueFilter(props, [{ label: 'Customer', value: 1 }, { label: 'Personal', value: 2 }]),
                accessor: (originalRow, rowIndex) => {
                    return originalRow?.Promise || "No promise"
                },
            },

            {
                id: 'labelNames',
                "Header": "Highlighters",
                accessor: (originalRow, rowIndex) => {
                    return <LabelHighlighters
                        labelNames={originalRow?.labelNames} />
                },
                Filter: ProjectLabelFilter
            },
            {
                id: 'card_title',
                "Header": "Project",
                accessor: (originalRow, rowIndex) => {
                    return <CustomBadge
                        cardId={originalRow?.trello_id}
                        name={originalRow?.card_title?.name}
                        labelStatus={originalRow?.card_title?.status} />
                },

            },
            {
                id: 'project_type',
                "Header": "Type",
                disableFilters: true,
                accessor: (originalRow, rowIndex) => {
                    return originalRow?.projectMetaData?.projectType
                },
            },

            {
                id: 'Folder',
                "Header": "Folder",
                Filter: FoldersFilter,
                accessor: (originalRow, rowIndex) => {
                    return originalRow?.Folder
                },
            },
            {
                id: 'Info',
                "Header": "Info.",
                disableFilters: true,
                accessor: (originalRow, rowIndex) => {
                    return <CustomInfo name={originalRow?.Info} />
                },
            },
            {
                id: 'MechWorkCardLabels',
                "Header": "Mech Labels",
                accessor: (originalRow, rowIndex) => {
                    return <WorkCardLabel child_cards={originalRow?.child_cards} cardType={"Mechanical"} />
                },
                Filter: WorkCardLabelFilter
            },
            {
                id: 'Estimated_Mech_Hours',
                "Header": "Mechanical",
                Filter: NumberRangeFilter,
                accessor: (originalRow, rowIndex) => {
                    return <CustomLabel hours={originalRow?.Estimated_Mech_Hours?.hours} child_cards={originalRow?.child_cards} cardType={"Mechanical"} />
                },
            },
            {
                id: 'Mechanical_Last_Member',
                "Header": "Worker",
                Filter: ManagersFilter,
                accessor: (originalRow, rowIndex) => {
                    return originalRow.Mechanical_Last_Member ? originalRow.Mechanical_Last_Member.map((val) => val?.fullName).join(', ') : "-"
                },
            },
            {
                id: 'Manager_Innitial_Mechanical',
                "Header": "Manager",
                Filter: ManagersFilter,
                accessor: (originalRow, rowIndex) => {
                    return originalRow.Manager_Innitial_Mechanical ? originalRow.Manager_Innitial_Mechanical.map((val) => val?.fullName).join(', ') : "-"
                },
            },
            {
                id: 'ElectWorkCardLabels',
                "Header": "Elect Labels",
                accessor: (originalRow, rowIndex) => {
                    return <WorkCardLabel child_cards={originalRow?.child_cards} cardType={"Electrical"} />
                },
                Filter: WorkCardLabelFilter
            },
            {
                id: 'Estimated_Elec_Hours',
                "Header": "Electrical",
                Filter: NumberRangeFilter,
                accessor: (originalRow, rowIndex) => {
                    return <CustomLabel hours={originalRow?.Estimated_Elec_Hours?.hours} child_cards={originalRow?.child_cards} cardType={"Electrical"} />
                },
            },
            {
                id: 'Electrical_Last_Member',
                "Header": "Worker",
                Filter: ManagersFilter,
                accessor: (originalRow, rowIndex) => {
                    return originalRow.Electrical_Last_Member ? originalRow.Electrical_Last_Member.map((val) => val?.fullName).join(', ') : "-"
                },
            },
            {
                id: 'Manager_Innitial_Electrical',
                "Header": "Manager",
                Filter: ManagersFilter,
                accessor: (originalRow, rowIndex) => {
                    return originalRow.Manager_Innitial_Electrical ? originalRow.Manager_Innitial_Electrical.map((val) => val?.fullName).join(', ') : "-"
                },
            },
            {
                id: 'PlumWorkCardLabels',
                "Header": "Plum Labels",
                accessor: (originalRow, rowIndex) => {
                    return <WorkCardLabel child_cards={originalRow?.child_cards} cardType={"Plumbing"} />
                },
                Filter: WorkCardLabelFilter
            },
            {
                id: 'Estimated_Plum_Hours',
                "Header": "Plumbing",
                Filter: NumberRangeFilter,
                accessor: (originalRow, rowIndex) => {
                    return <CustomLabel hours={originalRow?.Estimated_Plum_Hours?.hours} child_cards={originalRow?.child_cards} cardType={"Plumbing"} />
                },
            },
            {
                id: 'Plumbing_Last_Member',
                "Header": "Worker",
                Filter: ManagersFilter,
                accessor: (originalRow, rowIndex) => {
                    return originalRow.Plumbing_Last_Member ? originalRow.Plumbing_Last_Member.map((val) => val?.fullName).join(', ') : "-"
                },
            },
            {
                id: 'Manager_Innitial_Plumbing',
                "Header": "Manager",
                Filter: ManagersFilter,
                accessor: (originalRow, rowIndex) => {
                    return originalRow.Manager_Innitial_Plumbing ? originalRow.Manager_Innitial_Plumbing.map((val) => val?.fullName).join(', ') : "-"
                },
            },
            {
                "Header": "Comment",
                disableFilters: true,
                accessor: (originalRow, rowIndex) => {
                    return <CommentPopover projectInfo={originalRow} />
                },
            },
            {
                "Header": "Time Spent",
                id: 'Total_Spent_Hours',
                Filter: NumberRangeFilter,
                accessor: (originalRow, rowIndex) => {
                    return <HoursSpentPopover originalRow={originalRow} />
                },
            },
        ],
        []
    )
    const classes = useStyles();
    const mountedRef = useRef()
    const tableRef = useRef(null);
    const ITEMS_PER_PAGE = 10;
    const [filtersToApply, setFiltersToApply] = React.useState({})
    const prevFiltersToApply = usePrevious(filtersToApply)
    const dispatch = useDispatch()
    const { tableData } = useSelector(({ table }) => table);
    const [paginationOptions, setPaginationOptions] = useState({
        limit: ITEMS_PER_PAGE,
        offset: 0,
        currentPage: 1,
        orderBy: 'Latest',
        orderType: null,
    });

    const applyFilters = (filters) => {
        console.log("filters::", filters);
        setFiltersToApply(doFilter(filters, filterMap))
    }
    const getTableData = async () => {
        console.log("getPageData 3", paginationOptions);
        dispatch(TableServices.getTableData({
            limit: paginationOptions.limit,
            currentPage: paginationOptions.currentPage || 1,
            orderByColumn: paginationOptions.orderBy || null,
            columnOrderType: paginationOptions.orderType || null,
            ...filtersToApply
        }));
    }
    const getPageData = (pageNo, itemsPerPage) => {
        console.log("getPageData 1--", pageNo, itemsPerPage);
        setPaginationOptions(old => {
            return {
                limit: itemsPerPage,
                offset: (pageNo) * itemsPerPage,
                currentPage: pageNo,
                orderBy: old.orderBy,
                orderType: old.orderType
            }
        });
    }
    const setOrderByColumns = (column) => {
        console.log("column", column);
        if (paginationOptions.orderBy == column) {
            if (paginationOptions.orderType == "asc") {
                setPaginationOptions({
                    limit: ITEMS_PER_PAGE,
                    offset: (0) * ITEMS_PER_PAGE,
                    currentPage: 1,
                    orderType: "desc",
                    orderBy: column,
                });
            }
            if (paginationOptions.orderType == "desc") {
                setPaginationOptions({
                    limit: ITEMS_PER_PAGE,
                    offset: (0) * ITEMS_PER_PAGE,
                    currentPage: 1,
                    orderType: "asc",
                    orderBy: column,
                });
            }
        }
        else {
            setPaginationOptions({
                limit: ITEMS_PER_PAGE,
                offset: (0) * ITEMS_PER_PAGE,
                currentPage: 1,
                orderType: "asc",
                orderBy: column,
            });
        }
    }
    useEffect(() => {

        if (filtersToApply && prevFiltersToApply &&
            !_.isEqual(
                prevFiltersToApply,
                filtersToApply,
            )
        ) {

            setPaginationOptions(old => {
                return {
                    limit: ITEMS_PER_PAGE,
                    offset: (0) * ITEMS_PER_PAGE,
                    currentPage: 1,
                    orderBy: old.orderBy,
                    orderType: old.orderType
                }
            });
            if (paginationOptions.currentPage == 1 && paginationOptions.offset == 0) {
                getTableData();
            }
        }
    }, [filtersToApply, prevFiltersToApply])
    useEffect(() => {
        if (paginationOptions.limit) {
            // if (mountedRef.current) {
            dispatch(TableServices.getManagersData());
            getTableData();
            // }
        }
    }, [paginationOptions.currentPage, paginationOptions.limit, paginationOptions.offset, paginationOptions.orderBy, paginationOptions.orderType]);
    useEffect(() => {
        mountedRef.current = true
        const socket = io(BASE_WS_URL);
        if (socket) {
            socket.on("onTrelloCardUpdate", (res) => {
                const UpdatedData = JSON.parse(res)
                if (typeof UpdatedData?.child_cards?.[0] !== 'string')//fix for ignoring non populated children
                    dispatch(UpdateTableRow(UpdatedData))
                console.log("socket", UpdatedData);
            })
        };
        return () => socket.close();
    }, []);
    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <title> Priority Sheets | Report</title>
            </Helmet>
            <Box display="inline-flex" alignItems="center" className="tab-heading" >Active Projects</Box>
            <div >
                <Box display="flex" alignItems="center" className={classes.root}>
                    <TableActions sortBy={(value) => {
                        setPaginationOptions({
                            limit: ITEMS_PER_PAGE,
                            offset: (0) * ITEMS_PER_PAGE,
                            currentPage: 1,
                            orderBy: value,
                            orderType: null
                        });
                    }} />
                    <LegendIconList />
                    {/* <ReactTableHideShowColumns
                        columns={[...staticTableColumns]}
                        setHideColumn={(column, flag) => setHideColumn(column, flag)}
                        scrollableTableHiddenColumns={scrollableTableHiddenColumns}
                    /> */}
                </Box>
                <ReactMainTable
                    tableRef={tableRef}
                    applyFilters={applyFilters}
                    columns={columns}
                    itemsPerPage={paginationOptions.limit}
                    TotalLength={tableData?.totalItems || 0}
                    getPageData={getPageData}
                    orderByColumns={setOrderByColumns}
                    currentPage={paginationOptions.currentPage}
                    data={tableData?.trelloCards || []} />
            </div>
        </React.Fragment>
    )
}

export default ReactTable
