import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import UnLockIcon from '@material-ui/icons/LockOpen';
import Pagination from '@material-ui/lab/Pagination';
import { matchSorter } from 'match-sorter';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useColumnOrder, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable, useAsyncDebounce } from 'react-table';
import { useSticky } from 'react-table-sticky';
import dragIcon from '../../../assets/drag.svg';
import './table.css';
import { getConditionalSelectHeaderCheckboxProps } from './RowSelectionHelper';
import { DefaultColumnFilter } from './table-utils';
import { TableContainer } from '@material-ui/core';
import clsx from 'clsx';
import { AppTheme } from 'theme/customeTheme';
import { useMemo } from 'react';
import { useState } from 'react';
const BootstrapInput = withStyles((theme) => ({
    input: {
        borderRadius: 4,
        border: '1px solid #ced4da',
        padding: '10px 26px 10px 12px',
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "1rem",
        '& .MuiPaginationItem-icon': {
            color: '#3F7786',
        },
        '& .MuiTableCell-root': {
            textAlign: "center",
            padding: "12px",
            fontFamily: '"Mulish", sans-serif',
            fontWeight: 600
        },
        '& .MuiPaginationItem-outlined[aria-label="Go to next page"], & .MuiPaginationItem-outlined[aria-label="Go to previous page"], & .MuiPaginationItem-outlined[aria-label="Go to first page"], & .MuiPaginationItem-outlined[aria-label="Go to last page"]': {
            backgroundColor: '#DBE7EA',
            border: 0
        },
        '& .MuiPaginationItem-page.Mui-selected': {
            borderColor: '#3F7786',
        },
        '& .MuiPaginationItem-ellipsis': {
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "4px",
            height: "32px"
        },
        '& .MuiSelect-select:focus': {
            borderRadius: 4,
        },
        '& .MuiTableCell-head': {
            color: AppTheme.colors.primary,
            fontWeight: 600
        },
    },
    Pagination: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}));
const lockedIcon = (isLocked, toggleLock, column) => {
    return (
        column === "selection" || column === "id" || column === "action" ? "" :
            <IconButton onClick={() => toggleLock(!isLocked, column)} className="p-0">
                {!isLocked ? <UnLockIcon fontSize="small" style={{ color: '#90AEBB', fontSize: '1rem' }} />
                    : <LockIcon fontSize="small" style={{ color: '#90AEBB', fontSize: '1rem' }} />
                }
            </IconButton>
    )
}
const DragIcon = (column) => {
    return (
        column === "selection" || column === "id" || column === "action" ? "" :
            <img src={dragIcon} alt="drag" className="ml-2 cursor-grab" />
    )
}

const ReactTableComponent = forwardRef(({ columns, overflow, hiddenColumns, isCheckBox, data, setTestData, lockColumns, orderByColumns, setRowsForAction, onClientStatusSubmit, lastLockedIndex, isDataLoaded, itemsPerPage, getPageData, currentPage, applyFilters, rowDelete, shouldDisableRow, getTableData, getManagersData }, ref) => {
    const classes = useStyles();
    const [rowsSelection, setRowsSelection] = useState([]);

    const onNumberPagesChanged = (val) => {
        setPageSize(Number(val))
        gotoPage(0)
        // setPerPageItems(val)
    }
    const handleChange = (e, p) => {
        console.log('debug handle page', p);//TODO not working for last page
        gotoPage(p - 1)
    };

    const defaultColumn = useMemo(() => ({
        Filter: DefaultColumnFilter,
    }),
        []);
    function fuzzyTextFilterFn(rows, id, filterValue) {
        return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
    }
    fuzzyTextFilterFn.autoRemove = val => !val

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )
    const initialState = {
        hiddenColumns: [...hiddenColumns],
        selectedFlatRows: [],
        pageIndex: currentPage,
        pageSize: itemsPerPage,
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        setHiddenColumns,
        getToggleRowSelectedProps,
        prepareRow,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        selectedFlatRows,
        filteredRows,
        flatColumns,
        allColumns,
        setColumnOrder,
        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setPageCount,
        state: { selectedRowIds, pageIndex, pageSize, filters },
        toggleAllRowsSelected,


    } = useTable({
        columns,
        // data: isDataLoaded ? data?.data : [],
        data: data?.data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        initialState,
        manualPagination: true, // Tell the usePagination
        pageCount: Math.ceil(data?.count / itemsPerPage),
        manualFilters: true,
        // pageCount:Math.ceil(data?.count / 50),
    },
        useFilters, // useFilters!
        useGlobalFilter, useSortBy, useSticky,// us
        usePagination,
        useColumnOrder,
        useRowSelect,
    )
    const currentColOrder = React.useRef();



    const onApplyFilter = useAsyncDebounce(() => {
        applyFilters(filters)
    }, 200)
    const onOrderByColumn = (column) => {
        orderByColumns(column.id)
    }


    const toggleAllCheckboxes = () => {
        toggleAllRowsSelected();
    }
    const setReactTableCols = () => {
        setColumnOrder();
    }
    const hideReactTableColumns = (hiddenColumns) => {

        setHiddenColumns(hiddenColumns);
    }
    const getLeftPx = (id) => {
        var leftPx = 'auto';
        switch (id) {
            case 0:
                leftPx = '0px'
                break;
            case 1:
                leftPx = '39px'
                break;
            case 2:
                leftPx = '115px'
                break;
            case 3:
                leftPx = '194px'
                break;
            case 4:
                leftPx = '338px'
                break;
            case 5:
                leftPx = '482px'
                break;
            case 6:
                leftPx = '626px'
                break;
            case 7:
                leftPx = '770px'
                break;
            case 8:
                leftPx = '914px'
                break;
            case 9:
                leftPx = '1060px'
                break;
            case 10:
                leftPx = '1205px'
                break;
            case 11:
                leftPx = '1345px'
                break;
            //TODO add Mrugesh for other cols, May be based on column width

        }
        return leftPx;
    }

    useImperativeHandle(ref, () => (
        {
            hideReactTableColumns,
            headerGroups,
            toggleAllCheckboxes,
            setReactTableCols,
            gotoPage
        }
    ), []);
    useEffect(() => {
        onApplyFilter();
    }, [filters]);
    //added for updating state for actions

    useEffect(() => {
        if (selectedFlatRows.length != rowsSelection.length) {
            setRowsForAction(selectedFlatRows)
        }
        setRowsSelection(selectedFlatRows);

    }, [selectedFlatRows])
    useEffect(() => {
        getPageData(pageIndex, pageSize)
    }, [pageSize, pageIndex]);
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="table-container">
                <TableContainer className={clsx(classes.root, "custom-scrollbar", "table-responsive")}>
                    <Table {...getTableProps()}
                        // stickyHeader
                        aria-label="sticky table"
                        style={{ border: 'none', borderSpacing: '0', width: '100%', position: 'relative', }}>
                        <TableHead>
                            {/* <div> */}
                            {headerGroups.map((headerGroup, index) => (
                                // <>
                                <DragDropContext
                                    key={index}
                                    onDragStart={(e) => {
                                        console.log("debug onDragStart", e);

                                        currentColOrder.current = allColumns?.map(o => o.id);
                                    }}
                                    onDragUpdate={(dragUpdateObj, b) => {
                                        console.log("debug onDragUpdate", dragUpdateObj, b);

                                        const colOrder = [...currentColOrder.current];
                                        const sIndex = dragUpdateObj.source.index;
                                        const dIndex =
                                            dragUpdateObj.destination && dragUpdateObj.destination.index;

                                        if (typeof sIndex === "number" && typeof dIndex === "number") {
                                            colOrder.splice(sIndex, 1);
                                            colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);
                                            // if (lastLockedIndex < dragUpdateObj.destination.index)
                                            setColumnOrder(colOrder);

                                            // console.log(
                                            //   "onDragUpdate",
                                            //   dragUpdateObj.destination.index,
                                            //   dragUpdateObj.source.index
                                            // );
                                            // console.log(temp);
                                        }
                                    }}
                                >
                                    <Droppable droppableId="droppable" direction="horizontal">
                                        {(droppableProvided, snapshot) => (

                                            <TableRow
                                                {...headerGroup.getHeaderGroupProps()}
                                                ref={droppableProvided.innerRef}
                                                className="tr">
                                                {headerGroup.headers.map((column, index) => (
                                                    // Add the sorting props to control sorting. For this example
                                                    // we can add them into the header props
                                                    <Draggable
                                                        key={column.id}
                                                        draggableId={column.id}
                                                        index={index}
                                                        isDragDisabled={!column.accessor || column.sticky == 'left'}
                                                    >
                                                        {(provided, snapshot) => {
                                                            return (
                                                                <TableCell {...column.getHeaderProps()}
                                                                    // className={`th align-middle border-top-0 border-bottom-0 ${snapshot.isDragging ? "" : "fixed-column-" + index}`}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    // {...extraProps}
                                                                    ref={provided.innerRef}
                                                                    style={{
                                                                        backgroundColor: '#FFF6F6',
                                                                        outline: 'none',
                                                                        position: column?.sticky === 'left' ? 'sticky' : 'initial',
                                                                    }}
                                                                >
                                                                    <div className="custom-column-width" style={{
                                                                        display: 'flex', justifyContent: 'center', alignItems: "center", cursor: "pointer"
                                                                    }}>
                                                                        {/* {(lockedIcon(column.sticky == 'left', lockColumns, column.id))} */}

                                                                        <a 
                                                                        style = {{ position: column?.sticky === 'left' ? 'sticky' : 'initial'}} 
                                                                        {...column.getHeaderProps()} onClick={() => onOrderByColumn(column)}>{column.render('Header')}</a>
                                                                        {/* <a {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</a> */}
                                                                        {DragIcon(column.id)}
                                                                    </div>
                                                                </TableCell>
                                                            );

                                                        }}
                                                    </Draggable>
                                                ))}
                                            </TableRow>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                // </>
                            ))}
                            {/* </div> */}

                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()} className="tr bg-white">
                                    {headerGroup.headers.map((column, index) => (
                                        // Add the sorting props to control sorting. For this example
                                        // we can add them into the header props
                                        <th {...column.getHeaderProps()} className={"th align-middle border-top-0 border-bottom-0 bg-white fixed-column-" + index}>
                                            <div className={"fixed-column-" + index}>{column.canFilter ? column.render('Filter') : null}</div>

                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </TableHead>

                        <TableBody {...getTableBodyProps()}>
                            {
                                page.map(row => {
                                    prepareRow(row)
                                    return (
                                        <TableRow {...row.getRowProps()} className={row.isSelected ? 'test' : ''} >
                                            {row.cells.map((cell, i) => {
                                                return (
                                                    <TableCell
                                                        {...cell.getCellProps()}

                                                        style={{
                                                            position: cell?.column?.sticky === 'left' ? 'sticky' : 'initial',
                                                            // position: (columns ? columns.map((val) => val.sticky)),
                                                            left: getLeftPx(i),
                                                            // border: 'solid 1px gray',
                                                            minHeight: '47px',
                                                            backgroundColor: "#F2FAFD",//row.isSelected ? '#D9F1F0' : '#ffffff',
                                                            zIndex: 1,
                                                        }}
                                                        className={cell.column.id === 'selection' ? 'selection-td align-middle border-top border-bottom-0' : 'align-middle border-top border-bottom-0'}
                                                    >

                                                        {cell.render('Cell', { onClientStatusSubmit: onClientStatusSubmit, rowDelete: rowDelete, getTableData: getTableData, getManagersData: getManagersData, values: row?.values, trello_card_id: row?.original?.trello_id })}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div >
            <div className={classes.Pagination}>
                <div className="mb-2 mb-md-0">
                    <span className="mr-2">Rows per page:</span>
                    <Select
                        variant="outlined"
                        value={pageSize}
                        onChange={e => {
                            onNumberPagesChanged(e.target.value)
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                        input={<BootstrapInput />}
                        className="mr-4"
                    >
                        {[10, 20, 30, 40, 50].map((item, index) => {
                            return (
                                <MenuItem key={index} value={item}>{item}</MenuItem>
                            )
                        })}
                    </Select>
                </div>
                <Pagination
                    page={pageIndex + 1}
                    count={Math.ceil(data?.count / pageSize)}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChange}
                    siblingCount={0}
                    boundaryCount={2}
                    showFirstButton
                    showLastButton
                />
            </div>
        </>
    )
});
export default ReactTableComponent;