import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { AppTheme } from 'theme/customeTheme';

const Sidebar = ({ sideBarTitleInfo, sideBarInfo }) => {
    return (
        <Box pl={4} className="comments-sidebar">
            <Box textAlign={"center"} mb={"42px"}>
                <Button variant="contained" className="sidebar-button" style={{ backgroundColor: AppTheme.colors.primary }}>
                    {sideBarTitleInfo.thread_name}
                </Button>
            </Box>
            <Typography variant="h2" className="heading" gutterBottom>
                Project Name:
            </Typography>
            <Typography variant="h3" className="address font-weight-normal">
                {sideBarInfo?.card_title?.name}
            </Typography>
            <Typography variant="h2" className="heading">
                Folder : <span className="font-weight-normal">{sideBarInfo?.Folder}</span>
            </Typography>
        </Box>
    )
}

export default Sidebar;