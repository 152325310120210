export const trelloLabels = {
    labelName: {
        HourADay: "HOUR A DAY", 
        // Mechanical: "Mechanical", 
        // MechanicalT24: "Mechanical T-24", 
        // Electrical: "Electrical",
        // ElectricalT24: "Electrical T-24", 
        InternalReview: "Internal review",
        PartialDelivery: "Partial Delivery",
        PC: "PC",
        BlockedByInfo: "Blocked by Info",
        BlockedByTrade: "Blocked by Trade",
        OnHold: "On Hold",
        QDC: "QDC",
        QDCElect: "QDC-Elect",
        QDCMech: "QDC-Mech",
        QDCPlumb: "QDC-Plumb",
        NewInfoAdded: "New Info Added",
        Revision: "revision",
        // Plumbing: "Plumbing",
        BC: "BC",
        CreatePDF: "Create PDF",
        PS: "PS",
        DeliveryNote: "DELIVERY NOTE",
        // MainCard: "MAIN CARD",
        // PromisedDate: "Promised Date",
        NeededInfo: "NEEDED INFO",
    },
}
