import React, { useEffect, useState } from 'react';
import { AppTheme } from "theme/customeTheme";
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { styled, withStyles } from '@material-ui/styles';
import ProjectLabelType from './ProjectLabelType';
import { trelloLabels } from 'theme/trelloLabels';

const StyledMenu = styled((props) => (
    <Popover
        elevation={0}
        {...props}
    />
))(({ }) => ({
    '& .MuiPaper-root': {
        boxShadow:
            '0px 4px 4px rgba(0, 0, 0, 0.25);',
    },
}));
const CustomMenuItem = withStyles({
    root: {
        fontFamily: '"Poppins", sans-serif',
        color: "#4E4E4E",
        fontSize: 16,
        padding: "10px 15px",
        borderRadius: 16,
        margin: 16,
        letterSpacing: 0.2,
        minHeight: "auto",
        fontWeight: 500,
        border: "4px solid #96C0FF",
        fontStyle: "italic",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        // '&:hover': {
        //     backgroundColor: AppTheme.colors.primary,
        //     color: "#FFFFFF"
        //     // borderRadius: 5,
        // }
    }
})(MenuItem)
const CustomLabel = ({ hours, cardType, child_cards }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [previousCardType, setPreviousCardType] = React.useState(null);
    // const [previousCardList, setPreviousCardList] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    let labelName = Object.entries(trelloLabels?.labelName).map(([key, value]) => ({ key, value }));
    // const getUniqueLabelIcon = (array: []) => {
    //     let temp = [];
    //     for (let index = 0; index < array?.length; index++) {
    //         const sub = array[index]?.labelNames;
    //         if (cardType === previousCardType) {
    //             for (let index2 = 0; index2 < sub.length; index2++) {
    //                 const element = sub[index2];
    //                 if ((array[index].typeOfCard === cardType)) {
    //                     const findIndex = temp.findIndex((e) => e === element)
    //                     if (findIndex == -1) {
    //                         temp.push(element)
    //                     }
    //                 }
    //             }
    //         }
    //         else {
    //             setPreviousCardType(cardType);
    //             temp = [];
    //         }

    //     }
    //     return temp;
    // }
    const getUniqueListName = (array: []) => {
        let temp = [];
        for (let index = 0; index < array?.length; index++) {
            const sub = array[index]?.listName;
            if (cardType === previousCardType) {
                if ((array[index].typeOfCard === cardType)) {
                    const findIndex = temp.findIndex((e) => e === sub)
                    if (findIndex == -1) {
                        temp.push(sub)
                    }
                }
            }
            else {
                setPreviousCardType(cardType);
                temp = [];
            }

        }
        return temp;
    }

    return (
        <div>
            {/* {getUniqueLabelIcon(child_cards)?.map((labelIcon, i) => {
                return (
                    <ProjectLabelType
                        key={i}
                        labelType={labelIcon} />
                )
            })} */}
            <p className="text-truncate custom-label pointer"
                onClick={handleClick}
            >{Number(hours).toFixed(2)}
            </p>
            {getUniqueListName(child_cards)?.map((listName, i) => {
                return (
                    <p key={i}>{listName}</p>
                )
            })}
            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

            >
                <div className="hours-spent-popover">
                    <h3>Total Time Estimated : {Number(hours).toFixed(2)} hrs.</h3>
                    {child_cards?.filter(itm => itm.typeOfCard == cardType).map((item, i) => {
                        // console.log("item::", item);
                        return (
                            <div key={i} className="workcard-label">
                                {item?.labelNames?.map((workCardlabel, i) => {
                                    return (
                                        <ProjectLabelType
                                            key={i}
                                            labelType={workCardlabel} />
                                    )
                                })}
                                < CustomMenuItem
                                    key={i}
                                >
                                    <>
                                        <a style={{ color: '#136fae' }} href={'https://trello.com/c/' + item?.trello_id} target="_blank" rel="noreferrer" >
                                            {item.listName} :
                                        </a>
                                        <a style={{ color: 'black' }} href={'https://trello.com/c/' + item?.trello_id} target="_blank" rel="noreferrer" >
                                            {item.child_card_title} - {item.Estimated_Hours} hr.
                                        </a>
                                    </>
                                </CustomMenuItem>
                            </div>
                        )
                    })}
                </div>
            </StyledMenu>
        </div >
    )
}

export default CustomLabel;